import React, { useLayoutEffect } from 'react';
import FlexBox from '../../FlexBox';
import MobileIntroImg from '../../../assets/website/steps-images/mobile-steps-intro.png';
import ArrowDownImg from '../../../assets/website/steps-images/arrow-down.png';
import Step1Img from '../../../assets/website/steps-images/step1.png';
import Step2Img from '../../../assets/website/steps-images/step2.png';
import Step3Img from '../../../assets/website/steps-images/step3.png';
import Step4Img from '../../../assets/website/steps-images/step4.png';
import ArrowUpImg from '../../../assets/website/arrow-up.svg';
import './styles.scss';

const Steps = () => {
	const [isOpen, setOpen] = React.useState(false);
	const [screenWidth, setScreenWidth] = React.useState(0);

	useLayoutEffect(() => {
		const updateSize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return (
		<div className="steps-page">
			<FlexBox>
				<FlexBox
					jusitfyAround
					w100
					flexBoxClass="flex-mobile animated fadeInLeft pos-rel ovr-hdn section-height section-height-md">
					<FlexBox flexColumn jusitfyCenter flexBoxClass="intro-txt">
						<div className="font-medium fs-36 clr-white">Get started in</div>
						<div className="font-heavy fs-42 clr-white">4 easy steps</div>
					</FlexBox>
					<FlexBox jusitfyCenter alignCenter>
						<img src={MobileIntroImg} className="mobile-intro" />
					</FlexBox>
					<img src={ArrowUpImg} alt="ui" className="pos-abs w-20 o-30 left-0 arrow" />
					<img src={ArrowUpImg} alt="ui" className="pos-abs w-20 o-30 right-0 bottom-0 arrow" />
				</FlexBox>
				{!isOpen && (
					<FlexBox flexColumn jusitfyCenter flexBoxClass="know-more" onClick={() => setOpen(true)}>
						<div className="font-regular fs-16 clr-white">know more</div>
						<img src={ArrowDownImg} alt="know-more" />
						<div className="shade" />
					</FlexBox>
				)}
			</FlexBox>
			{isOpen && (
				<FlexBox flexColumn jusitfyCenter flexBoxClass="pos-rel ovr-hdn steps">
					<FlexBox jusitfyCenter alignCenter flexBoxClass="mr-b-60 animated fadeInLeft step section-height">
						<div className="font-heavy fs-42 clr-white w-50 o-50 algn-cntr">step 1</div>
						{screenWidth > 768 && <img src={Step1Img} alt="step1" className="mb-img" />}
						<FlexBox flexColumn jusitfyCenter flexBoxClass="w-50">
							<div className="font-heavy fs-36 clr-white">Sign-up</div>
							<div className="font-medium fs-16 clr-primary">
								Download the app from Google playstore or App store and sign-up
							</div>
						</FlexBox>
						{screenWidth <= 768 && <img src={Step1Img} alt="step1" className="mb-img" />}
					</FlexBox>
					<FlexBox jusitfyCenter alignCenter flexBoxClass="mr-b-60 animated fadeInRight step section-height">
						<div className="font-heavy fs-42 clr-white w-50 o-50 algn-cntr">step 2</div>
						{screenWidth > 768 && <img src={Step2Img} alt="step2" className="mb-img" />}
						<FlexBox flexColumn jusitfyCenter flexBoxClass="w-50">
							<div className="font-heavy fs-36 clr-white line-h80">Add details</div>
							<div className="font-medium fs-16 clr-primary">
								Add and verify your phone number, PAN number and bank account details
							</div>
						</FlexBox>
						{screenWidth <= 768 && <img src={Step2Img} alt="step2" className="mb-img" />}
					</FlexBox>
					<FlexBox jusitfyCenter alignCenter flexBoxClass="mr-b-60 animated fadeInLeft step section-height">
						<div className="font-heavy fs-42 clr-white w-50 o-50 algn-cntr">step 3</div>
						{screenWidth > 768 && <img src={Step3Img} alt="step3" className="mb-img" />}
						<FlexBox flexColumn jusitfyCenter flexBoxClass="w-50">
							<div className="font-heavy fs-36 clr-white line-h80">Select tier</div>
							<div className="font-medium fs-16 clr-primary">
								Choose your preferred tier best suited to maximize your earnings
							</div>
						</FlexBox>
						{screenWidth <= 768 && <img src={Step3Img} alt="step3" className="mb-img" />}
					</FlexBox>
					<FlexBox jusitfyCenter alignCenter flexBoxClass="animated fadeInRight step section-height">
						<div className="font-heavy fs-42 clr-white w-50 o-50 algn-cntr">step 4</div>
						{screenWidth > 768 && <img src={Step4Img} alt="step4" className="mb-img" />}
						<FlexBox flexColumn jusitfyCenter flexBoxClass="w-50">
							<div className="font-heavy fs-36 clr-white line-h80">Start earning!</div>
							<div className="font-medium fs-16 clr-primary">Add money and invest to see your money grow!</div>
						</FlexBox>
						{screenWidth <= 768 && <img src={Step4Img} alt="step4" className="mb-img" />}
					</FlexBox>
					<img src={ArrowUpImg} alt="ui" className="pos-abs w-20 o-30 left-0 top-0 arrow" />
					<img src={ArrowUpImg} alt="ui" className="pos-abs w-20 o-30 right-0 arrow arrow-right-neg" />
					<img src={ArrowUpImg} alt="ui" className="pos-abs w-20 o-30 left-0 arrow arrow-left-neg" />
					<img src={ArrowUpImg} alt="ui" className="pos-abs w-20 o-30 right-0 bottom-0 arrow" />
				</FlexBox>
			)}
		</div>
	);
};

export default Steps;
