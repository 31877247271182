import React from 'react';

import './styles.scss';
import FlexBox from '../../FlexBox';
import Button from '../../Button';
import Card from '../../Card';
import { Plans } from './FindPlan.constants';
import SeparatorComponent from '../../Separator';
import { currencyFormat } from '../../../utils/utils';
import CalendarIcon from '../../../assets/website/plans-card-icons/calendar.svg';
import NoteIcon from '../../../assets/website/plans-card-icons/note.svg';
import RecommendedFlag from '../../../assets/website/plans-card-icons/recommended.png';
import RecommendedMFlag from '../../../assets/website/plans-card-icons/recommended-mobile.png';

const FindPlan = () => {
	const [screenWidth, setScreenWidth] = React.useState(0);

	React.useLayoutEffect(() => {
		const updateSize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return (
		<div className="pos-rel find-plan">
			<FlexBox flexColumn jusitfyCenter w100 flexBoxClass="flex-mobile animated fadeInLeft section-height">
				<div className="font-heavy algn-cntr mb-2 fs-72 clr-white">Find Your Perfect Plan</div>
				<div className="font-medium algn-cntr mb-4 fs-44 clr-primary sub">~ Plans starting with 0 lock-in period ~</div>
				<FlexBox jusitfyCenter alignCenter w100 flexBoxClass="h-100 flex-mobile word-break cntr">
					{Plans.map((cardData) => (
						<Card key={cardData.interest} cardClass="plan-card">
							{screenWidth > 768 && cardData.interest === 10.5 && (
								<img src={RecommendedFlag} alt="recommended" className="recommended" />
							)}
							{screenWidth <= 768 && cardData.interest === 10.5 && (
								<img src={RecommendedMFlag} alt="recommended" className="recommended" />
							)}
							<FlexBox jusitfyCenter alignCenter w100 flexColumn flexBoxClass="h-100 plan-card-content">
								<div className="h-50 pos-rel">
									<img src={cardData.icon} alt={cardData.interest} className="h-100" />
								</div>
								<div className="h-50 algn-cntr clr-primary pos-rel">
									<div className="font-heavy fs-16 mr-t-5">Grow at up to</div>
									<div className="font-medium fs-32 clr-teal-3 mr-t-5 mr-b-5">{cardData.interest}% returns*</div>
									<SeparatorComponent className="separator" />
									<div className="font-regular fs-14 mr-t-25 mr-b-5 with-icon">
										<img src={CalendarIcon} alt="calendar" className="small-icon" /> {cardData.lockin}{' '}
										{cardData.lockin === 0 ? 'lock-in period' : 'months lock-in period'}
									</div>
									<div className="font-regular fs-14 with-icon mr-b-25">
										<img src={NoteIcon} alt="money" className="small-icon" />{' '}
										{cardData.min === 0 ? 'no min. amount' : `₹${currencyFormat(cardData.min)} min. amount`}
									</div>
									<div className="font-regular tnc">*T&C Apply</div>
								</div>
							</FlexBox>
						</Card>
					))}
				</FlexBox>

				<FlexBox jusitfyCenter alignCenter flexBoxClass="mr-t-30">
					<Button
						text="Invest now"
						onClick={() => {
							window.open('https://twelveclub.onelink.me/2Cmd/website', '_blank');
						}}
						buttonClassName="btn font-medium fs-32"
					/>
				</FlexBox>
			</FlexBox>
		</div>
	);
};

export default FindPlan;
