import React from 'react';

const Logo = ({ variant, size, className }) => {
	const color = variant === 'primary' ? '#035857' : '#ffffff';
	const width = 169 * (size ?? 1);
	const height = 47 * (size ?? 1);

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 169 47"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			onClick={() => {
				window.close();
			}}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M130.215 45.9143V43.2402H130.124C128.786 45.9596 126.343 46.7294 123.485 46.7294C120.673 46.7294 117.816 45.7789 115.972 43.5579C113.898 41.0197 113.667 38.9347 113.667 35.808V20.7617H121.319V34.6745C121.319 37.5299 121.965 39.705 125.421 39.705C128.602 39.705 129.8 37.4846 129.8 34.5845V20.7617H137.452V45.9143H130.215Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M155.079 26.9252C151.438 26.9252 148.718 29.7353 148.718 33.2705C148.718 36.8503 151.207 39.751 154.941 39.751C158.629 39.751 161.348 36.8951 161.348 33.3152C161.348 29.8707 158.674 26.9252 155.079 26.9252ZM156.093 46.7754C153.465 46.7754 150.1 45.6873 148.764 43.2403H148.672V45.9144H141.343V12.3774H148.994V22.7557C150.977 20.6706 153.558 19.9009 156.416 19.9009C158.259 19.9009 160.103 20.354 161.809 21.1244C166.74 23.3901 169 27.9675 169 33.1792C169 40.7474 164.068 46.7754 156.093 46.7754Z"
				fill={color}
			/>
			<path fillRule="evenodd" clipRule="evenodd" d="M102.291 45.914H109.942V12.377H102.291V45.914Z" fill={color} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M91.5853 35.5224C90.6807 37.7294 88.4838 39.2928 85.9193 39.2928C82.5506 39.2928 79.8107 36.5989 79.8107 33.2869C79.8107 29.9754 82.5506 27.2809 85.9193 27.2809C88.5742 27.2809 90.8331 28.9581 91.6728 31.2884H99.44C98.455 24.8211 92.7677 19.8452 85.9193 19.8452C78.3807 19.8452 72.2473 25.8756 72.2473 33.2869C72.2473 40.6993 78.3807 46.7291 85.9193 46.7291C92.6838 46.7291 98.3132 41.874 99.3993 35.5224H91.5853Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M65.2616 0C57.723 0 51.5896 6.03035 51.5896 13.4422C51.5896 20.8541 57.723 26.8839 65.2616 26.8839C72.8007 26.8839 78.9342 20.8541 78.9342 13.4422C78.9342 6.03035 72.8007 0 65.2616 0ZM65.2616 7.43627C68.6302 7.43627 71.3708 10.1302 71.3708 13.4422C71.3708 16.7537 68.6302 19.4482 65.2616 19.4482C61.8935 19.4482 59.153 16.7537 59.153 13.4422C59.153 10.1302 61.8935 7.43627 65.2616 7.43627Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.05774 0.944824C9.05774 4.68387 5.97507 8.07086 2.17211 8.07086H0V14.1419H8.24882L8.25177 45.936H15.8152V0.945405L9.05774 0.944824Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M96.64 0.453125L52.3606 43.9884L54.7271 46.3152L99.0066 2.78045L96.64 0.453125Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.6953 24.2881C47.235 21.7081 48.8032 18.1974 48.8032 14.3311C48.8032 6.42947 42.2644 0 34.226 0C26.1893 0 19.6499 6.42947 19.6499 14.3311H27.2139C27.2139 10.5293 30.3592 7.43627 34.226 7.43627C38.0933 7.43627 41.2398 10.5293 41.2398 14.3311C41.2398 16.1466 40.5166 17.7959 39.3454 19.0287L20.4736 38.3909V45.9143H47.7402V38.3909H30.3893L44.6953 24.2881Z"
				fill={color}
			/>
		</svg>
	);
};

export default Logo;
