export const UserReviews = [
	{
		title: 'Excellent application',
		desc: 'Very secure app for investment, just go with 12% club',
		name: 'a@5678',
	},
	{
		title: 'Can earn daily!!',
		desc: 'So far so good, easy to add and withdraw money any time. Can earn daily',
		name: 'Shankar dv',
	},
	{
		title: 'Easy deposit & withdrawal',
		desc: 'Best app for investment... Easy deposit and withdrawal',
		name: 'Yashkumar Ramteke',
	},
	{
		title: 'Very great experience',
		desc: 'Smart ways to get interest rate at best price',
		name: 'Samjain255',
	},
];
