import React from 'react';

import FlexBox from '../../FlexBox';
import Card from '../../Card';
import GoldIcon from '../../../assets/website/diversify-portfolio-card-icons/gold.png';
import FdsIcon from '../../../assets/website/diversify-portfolio-card-icons/fds.png';

import './styles.scss';

const DiversifyPortfolio = () => {
	return (
		<div className="pos-rel diversify-portfolio section-height section-height-md">
			<FlexBox flexColumn jusitfyAround w100 flexBoxClass="h-100 flex-mobile animated fadeInLeft">
				<div className="font-heavy algn-cntr mr-b-10 fs-72 clr-teal-2">Diversify Your Portfolio</div>
				<div className="font-medium algn-cntr mr-b-30 fs-45 clr-primary">Coming soon...</div>
				<FlexBox jusitfyCenter alignCenter w100 flexBoxClass="h-100 flex-mobile word-break">
					<Card cardClass="intro-card">
						<img src={GoldIcon} alt={'LiquiGold'} className="h-100 card-img" />
						<div className="font-medium fs-22 clr-teal-3 mr-t-5">LiquiGold</div>
					</Card>

					<Card cardClass="intro-card">
						<img src={FdsIcon} alt={'FDs'} className="h-100 card-img" />
						<div className="font-medium fs-22 clr-teal-3 mr-t-5">FDs</div>
					</Card>
				</FlexBox>
			</FlexBox>
		</div>
	);
};

export default DiversifyPortfolio;
