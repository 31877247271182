import React from 'react';
import FlexBox from '../../FlexBox';
import { LenderDetails } from './LendingPartners.constants';
import './styles.scss';
import SeparatorComponent from '../../Separator';

const LendingPartners = () => {
	const [screenWidth, setScreenWidth] = React.useState(0);

	React.useLayoutEffect(() => {
		const updateSize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return (
		<div className="pos-rel lending-partners">
			<FlexBox flexColumn jusitfyAround w100 flexBoxClass="h-100 flex-mobile animated fadeInLeft">
				<div className="font-medium algn-cntr clr-primary fs-26">Our Lending Partners</div>
				{screenWidth <= 768 && <SeparatorComponent className="seperator" />}
				<FlexBox jusitfyAround alignCenter w100 flexBoxClass="h-100 flex-mobile word-break cntr">
					{LenderDetails.length > 0 &&
						LenderDetails.map((item, key) => {
							return (
								<div className="animated fadeInLeft" key={item.websitelink}>
									<FlexBox alignCenter jusitfyCenter flexBoxClass="h-50 ln-dt">
										<img
											src={item.image}
											className="w-40 pointer partner-img"
											alt={item.websitelink}
											onClick={() => this.handleIconClick(item.websitelink)}
										/>

										<div className="fs-14 font-regular mr-l-30 mr-t-10 clr-primary">
											<p className="font-medium">{item.name}</p>
											<p>For more details:</p>
											<p>Email: {item.email}</p>
											<p>Phone: {item.phoneNo}</p>
										</div>
									</FlexBox>
								</div>
							);
						})}
				</FlexBox>
			</FlexBox>
		</div>
	);
};

export default LendingPartners;
