import React from 'react';

import { Screen } from '../../components';
import {
	Intro,
	PoweredByBP,
	LendingPartners,
	DiversifyPortfolio,
	FindPlan,
	Reviews,
	Steps,
} from '../../components/Home';
import './styles.scss';

const Home = () => {
	React.useEffect(() => {
		window.scroll({ top: 0, behavior: 'smooth' });
	}, []);

	return (
		<Screen screenClass="home-screen">
			<Intro />
			<FindPlan />
			<Reviews />
			<Steps />
			<DiversifyPortfolio />
			<PoweredByBP />
			<LendingPartners />
		</Screen>
	);
};

export default Home;
