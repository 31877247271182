import React from 'react';
import FlexBox from '../../FlexBox';
import Card from '../../Card';
import { HomeCards } from './Intro.constants';
import PlayStoreImg from '../../../assets/website/play-store.png';
import AppStoreImg from '../../../assets/website/app-store.png';
import ArrowUpImg from '../../../assets/website/arrow-up.svg';
import IntroPhoneImg from '../../../assets/website/intro-mobile.png';
import './styles.scss';

const Intro = () => {
	return (
		<div className="intro-page pos-rel ovr-hdn section-height">
			<FlexBox flexColumn jusitfyCenter alignCenter flexBoxClass="pos-rel animated zoomIn w-100 h-100 z-5">
				<FlexBox flexBoxClass="w-100 flex-md-col" jusitfyCenter alignCenter>
					<FlexBox flexColumn jusitfyCenter flexBoxClass="pos-rel h-100 w-65vw">
						<div className="mr-b-10">
							<div className="font-medium fs-22 clr-teal uppercase">Maximize your wealth</div>
							<hr className="hr-sep" />
						</div>
						<div className="line-h80 mr-b-60">
							<div className="font-heavy fs-88 clr-primary">Grow with</div>
							<div className="font-heavy fs-88 clr-teal-2">industry leading</div>
							<div className="font-heavy fs-88 clr-primary">returns</div>
						</div>
						<FlexBox flexBoxClass="download-app">
							<img
								src={PlayStoreImg}
								className="app-download pointer"
								alt="play-store"
								onClick={() => {
									window.open('https://twelveclub.onelink.me/2Cmd/website', '_blank');
								}}
							/>
							<img
								src={AppStoreImg}
								className="app-download pointer"
								alt="app-store"
								onClick={() => {
									window.open('https://twelveclub.onelink.me/2Cmd/website', '_blank');
								}}
							/>
						</FlexBox>
					</FlexBox>
					<FlexBox justifyFlexEnd alignCenter flexBoxClass="pos-rel h-100 w-35vw md-justify-center">
						<img alt="invest and earn" src={IntroPhoneImg} className="intro-phone w-auto h-100" />
					</FlexBox>
				</FlexBox>
				<FlexBox flexBoxClass="w-100 h-100" flexColumn alignCenter jusitfyEvenly>
					<div className="font-heavy fs-22 clr-primary what-makes-great">What makes it a great investment?</div>
					<div className="w-100 h-100 intro-card-cntr grid grid-col-4 grid-md-col-2 justify-center">
						{HomeCards.map((cardData) => (
							<Card key={cardData.text} cardClass="intro-card">
								<img src={cardData.icon} alt={cardData.text} className="h-100 card-img" />
								<div
									className="font-medium fs-22 clr-teal-3 mr-t-5"
									dangerouslySetInnerHTML={{ __html: cardData.text }}></div>
							</Card>
						))}
					</div>
				</FlexBox>
			</FlexBox>

			<img src={ArrowUpImg} alt="ui" className="pos-abs arr o-30 top-22 left-0" />
			<img src={ArrowUpImg} alt="ui" className="pos-abs arr arr-btm o-30 right-0" />
		</div>
	);
};

export default Intro;
