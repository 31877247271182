//React
import React, { useCallback, useEffect } from 'react';
//Redux
import cx from 'classnames';
import { FlexBox } from '..';

import './styles.scss';
import Logo from '../Logo';

import FacebookLogo from '../../assets/website/social-icons/facebook.png';
import LinkedinLogo from '../../assets/website/social-icons/linkedin.png';
import TwitterLogo from '../../assets/website/social-icons/twitter.png';

const { width, height } = window.screen;

const Footer = ({ footerClass, children }) => {
	const [screenWidth, setScreenWidth] = React.useState(0);

	React.useLayoutEffect(() => {
		const updateSize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return (
		<FlexBox jusitfyBetween alignCenter flexBoxClass={cx('footer up-to-down-scale font-medium w-100', footerClass)}>
			<Logo size={screenWidth > 768 ? 0.65 : 0.5} />
			<FlexBox jusitfyAround alignCenter flexBoxClass="block clr-teal-2 gap-4 navs w-50">
				<p
					className="mr-0"
					onClick={() => {
						window.open(`mailto:support@twelve.club?subject=${encodeURIComponent(`Issue with 12% Club`)}`, '_blank');
					}}>
					Contact Us
				</p>
				<p
					className="mr-0"
					onClick={() => {
						window.open('/#/faq', '_blank');
					}}>
					FAQs
				</p>
				<p
					className="mr-0"
					onClick={() => {
						window.open('/#/privacy-policy', '_blank');
					}}>
					Privacy Policy
				</p>
				<p
					className="mr-0"
					onClick={() => {
						window.open('/#/terms-conditions', '_blank');
					}}>
					Terms of Services
				</p>
			</FlexBox>

			<FlexBox jusitfyCenter alignCenter flexBoxClass="block clr-teal-2 gap-4">
				<img
					src={FacebookLogo}
					className="social-icon"
					onClick={() => window.open('https://m.facebook.com/12percent.club/', '_blank')}
				/>
				<img
					src={TwitterLogo}
					className="social-icon"
					onClick={() => window.open('https://twitter.com/12percent_club', '_blank')}
				/>
				<img
					src={LinkedinLogo}
					className="social-icon"
					onClick={() => window.open('https://www.linkedin.com/company/12percentclub', '_blank')}
				/>
			</FlexBox>
		</FlexBox>
	);
};

export default Footer;
