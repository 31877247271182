import React from 'react';

import FlexBox from '../../FlexBox';
import BpLogo from '../../../assets/website/bp-logo.png';

import './styles.scss';

const PoweredByBP = () => {
	return (
		<div className="powered-by-bp">
			<FlexBox jusitfyCenter alignCenter flexBoxClass="gap-4">
				<span className="uppercase font-regular">POWERED BY</span>
				<img src={BpLogo} alt="bharatpe" className="w-20" />
			</FlexBox>
		</div>
	);
};

export default PoweredByBP;
