import React from 'react';
import cx from 'classnames';
import './styles.scss';
const Card = ({ cardClass, children, cardStyle, onClick }) => {
	return (
		<div className={cx('card', cardClass)} style={cardStyle} onClick={onClick}>
			{children}
		</div>
	);
};
export default Card;
