//React
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
//Libraries
import cx from 'classnames';
//Components
import { FlexBox } from '..';
//Styles
import './styles.scss';
//Assets
import Button from '../Button';
import { history } from '../../utils';
import Logo from '../Logo';

import PlayStoreImg from '../../assets/website/play-store-header.png';
import AppStoreImg from '../../assets/website/app-store-header.png';

const { width, height } = window.screen;
const Header = ({ headerClass, darkBackground, children, buttonText, buttonAction, scrollAction }) => {
	const [screenWidth, setScreenWidth] = useState(0);
	const url = window.location.href.split('/').splice(-1)[0];

	useLayoutEffect(() => {
		const updateSize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	if (url === 'help-support') {
		return null
	}

	return (
		<div className={cx(darkBackground && 'dark-background', 'header animated fadeInDown')}>
			<FlexBox alignCenter flexBoxClass="actions clr-primary font-heavy h-100" jusitfyBetween>
				<FlexBox alignCenter flexBoxClass="actions clr-primary font-heavy h-100" w100>
					<Logo variant="primary" className="w-7 logo" size={screenWidth > 768 ? 1 : 0.5} />
				</FlexBox>
				{screenWidth > 768 && (
					<Button
						text={'Download App'}
						onClick={() => {
							window.open('https://twelveclub.onelink.me/2Cmd/website', '_blank');
						}}
						buttonClassName="btn font-medium fs-28"
					/>
				)}
				{screenWidth <= 768 && (
					<div className="flex">
						<img
							src={PlayStoreImg}
							className="pointer w-50"
							onClick={() => {
								window.open('https://twelveclub.onelink.me/2Cmd/website', '_blank');
							}}
						/>
						<img
							src={AppStoreImg}
							className="pointer w-50"
							onClick={() => {
								window.open('https://twelveclub.onelink.me/2Cmd/website', '_blank');
							}}
						/>
					</div>
				)}
			</FlexBox>
		</div>
	);
};

export default Header;
