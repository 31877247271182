import React, { useLayoutEffect, useMemo, useState } from 'react';

import Slider from 'react-slick';
import FlexBox from '../../FlexBox';

import NumbersImg from '../../../assets/website/reviews/numbers.png';
import CalloutOpenImg from '../../../assets/website/reviews/open.png';
import CalloutCloseImg from '../../../assets/website/reviews/close.png';
import NextIcon from '../../../assets/website/reviews/next.png';
import PrevIcon from '../../../assets/website/reviews/prev.png';
import PhoneReturnImg from '../../../assets/website/watch-returns/pnone-returns.png';
import { UserReviews } from './Reviews.constants';
import Card from '../../Card';
import './styles.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NextIconComp = (props) => {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={style} onClick={onClick}>
			<img src={NextIcon} alt="next" className="lr-icon" />
		</div>
	);
};

const PrevIconComp = (props) => {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={style} onClick={onClick}>
			<img src={PrevIcon} alt="prev" className="lr-icon" />
		</div>
	);
};

const Reviews = () => {
	const [screenWidth, setScreenWidth] = useState(0);

	useLayoutEffect(() => {
		const updateSize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	const settings = useMemo(
		() => ({
			className: 'center',
			centerMode: true,
			infinite: true,
			slidesToShow: 4,
			speed: 500,
			nextArrow: screenWidth > 768 ? <NextIconComp /> : null,
			prevArrow: screenWidth > 768 ? <PrevIconComp /> : null,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
					},
				},
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 1120,
					settings: {
						slidesToShow: 2,
					},
				},
			],
		}),
		[screenWidth],
	);

	return (
		<div className="reviews">
			<FlexBox flexColumn jusitfyCenter alignCenter w100 flexBoxClass="h-100 flex-mobile animated fadeInLeft">
				<div className="font-medium algn-cntr mb-2 mt-2 fs-44 clr-primary">Reviews & experiences of our investors</div>
				<div className="slider-container">
					<Slider {...settings}>
						{UserReviews.map((cardData) => (
							<Card key={cardData.name} cardClass="pos-rel review-card">
								<div className="font-medium fs-24 clr-primary">{cardData.title}</div>
								<div className="font-regular fs-14 clr-teal-2 mr-t-15">{cardData.desc}</div>
								<div className="font-heavy fs-14 clr-primary mr-t-15">- {cardData.name}</div>
								<img src={CalloutOpenImg} alt="callout-open" className="callout-top" />
								<img src={CalloutCloseImg} alt="callout-close" className="callout-bottom" />
							</Card>
						))}
					</Slider>
				</div>
				<img src={NumbersImg} alt="stats" className="w-50 mr-t-50 stats" />
			</FlexBox>

			<div className="pos-rel watch-returns section-height section-height-md">
				<FlexBox jusitfyAround alignCenter w100 flexBoxClass="h-100 flex-mobile animated fadeInLeft">
					<img src={PhoneReturnImg} alt="watch your returns" className="phone-return-img" />
					<FlexBox flexColumn jusitfyAround flexBoxClass="h-100 w-60 p-40">
						<div className="clr-primary font-heavy fs-36 mr-b-25 line-h45">
							Watch your returns grow <span className="clr-teal-2">everyday</span>
						</div>
						<div className="clr-primary font-medium fs-20">Track returns daily along with your lifetime earnings</div>
					</FlexBox>
				</FlexBox>
			</div>
		</div>
	);
};

export default Reviews;
